<template>
    <section class="home-slide-banners">
        <swiper :options="swiperOptions" class="sliders">
            <swiper-slide v-for="(item, index) in banners" :key="index">
                <template v-if="item.link">
                    <a :href="item.link" target="_blank" :title="item.title">
                        <img :src="getBannerDisplay(item)" :alt="item.title" :title="item.title" />
                    </a>
                </template>
                <template v-else>
                    <img :src="getBannerDisplay(item)" :alt="item.title" :title="item.title" />
                </template>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </section>
</template>

<script>
import MixinScreenSize from '@/mixins/screenSize';

export default {
    mixins: [ MixinScreenSize ],
	data() {
        return {
            swiperOptions: {
                loop: true,
                autoplay: {
                    delay: 4000
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        }
    },
    props: {
        banners: Array
    },
    methods: {
        getBannerDisplay(item) {
            if (this.windowWidth > this.screenSmall) {
                return item.imgLarge;
            } else {
                return item.imgSmall;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.home-slide-banners {
    width: 100%;
    height: 100%;

    .sliders {
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>