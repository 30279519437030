<template>
	<div class="home">
		<div class="title">
			<h1>YELLOWTiRE ที่สุดเรื่องยาง เช็ค ชอป ชัวร์ | ซื้อยางออนไลน์ ราคายางรถยนต์ชั้นนำ ทุกรุ่น ค้นหาร้านยาง เทียบโปรโมชั่นจากร้านทั่วไทย</h1>
			<p>เช็คราคายางรถยนต์ชั้นนำ ทุกยี่ห้อ ทุกรุ่น จากร้านยางรถยนต์ใกล้ฉันทั่วไทย พร้อมข้อมูลยางรถยนต์ และ โปรโมชั่นยาง ที่ครบที่สุด อัพเดตล่าสุด คลิก YELLOWTiRE.com ที่สุดเรื่องยาง เช็ค ชอป ชัวร์</p>
		</div>

		<section class="top-section">
			<div class="container">
				<div class="tags">
					<div class="loading" v-if="!isContentReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>
					<home-tags :tags="generalContent.tags" v-if="isContentReady"></home-tags>
				</div>

				<div class="main mt-2">
					<!-- Desktop Only -->
					<div class="search-tire d-none d-xl-block">
						<home-tire-search 
							:tireSizeOption="tireSizeOptionData" 
							:carBrandData="carBrandData"
							:tireTypeData="tireTypeData"
							v-if="tireSizeOptionData && carBrandData.length > 0 && tireTypeData.length > 0">
						</home-tire-search>
						<home-gadget-search></home-gadget-search>
					</div>
					<div class="banners">
						<loader-content :isDisplay="!isSliderReady"></loader-content>
						<home-slider-banner :banners="sliderContent.banners" v-if="isSliderReady"></home-slider-banner>
					</div>
				</div>

				<!-- Mobile Only -->
				<div class="brands mt-3 d-md-none">
					<template v-if="!isBrandListReady">
						<div class="loading">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>
					</template>
					<home-brands-list-mobile :brands="brandsContent.brands" v-if="isBrandListReady"></home-brands-list-mobile>
				</div>

				<!-- Desktop Only -->
				<div class="section-tire-search-desktop">
					<div class="brands d-none d-md-block">
						<template v-if="!isBrandListReady">
							<div class="loading">
								<div class="item">
									<loader-content :isDisplay="true"></loader-content>
								</div>
							</div>
						</template>
						<home-brands-list :brands="brandsContent.brands" v-if="isBrandListReady"></home-brands-list>
					</div>

					<!-- Mobile Only -->
					<div class="mobile-search d-xl-none">
						<div class="row">
							<div class="col col-12">
								<home-tire-search 
									:tireSizeOption="tireSizeOptionData" 
									:carBrandData="carBrandData"
									:tireTypeData="tireTypeData"
									v-if="tireSizeOptionData && carBrandData.length > 0 && tireTypeData.length > 0">
								</home-tire-search>
								<home-gadget-search></home-gadget-search>
							</div>
						</div>
					</div>
				</div>

				<!-- Desktop Only -->
				<div class="mt-2 d-none d-xl-block" v-if="isContentReady">
					<gadget-ads-banner :banner="generalContent.ads" name="bannerPageHome" :isBannerReady="isContentReady"></gadget-ads-banner>
				</div>
			</div>
		</section>

		<div class="container">
			<div class="product-recommend mt-4">
				<div class="content-bar mb-2">
					<div class="bar-title">ยางรถยนต์ยอดนิยม</div>
					<router-link :to="routerPath.TIRE" class="view-more" title="ค้นหายางรถยนต์">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
				</div>
				<div class="loading" v-if="!isPopularTireReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<div class="d-none d-lg-block">
					<product-grid-home
						v-if="isPopularTireReady"
						:products="popularTire.tires"
						:promoteData="popularTire.promotes"
						:adsPosition="popularTire.adsPositionDesktop">
					</product-grid-home>
				</div>
				<div class="d-block d-lg-none">
					<product-grid-home
						v-if="isPopularTireReady"
						:products="popularTire.tires"
						:promoteData="popularTire.promotes"
						:adsPosition="popularTire.adsPositionMobile">
					</product-grid-home>
				</div>
			</div>

			<!-- Mobile Only -->
			<div class="mt-2 d-xl-none" v-if="isContentReady">
				<gadget-ads-banner :banner="generalContent.ads" name="bannerPageHome" :isBannerReady="isContentReady"></gadget-ads-banner>
			</div>

			<div class="store-list mt-3">
				<div class="content-bar mb-2">
					<div class="bar-title">ร้านยางและศูนย์บริการพร้อมติดตั้ง</div>
					<router-link :to="routerPath.STORE" class="view-more" title="ค้นหาร้านยาง">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
				</div>
				<div class="loading" v-if="!isStoreListReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<store-grid
					:stores="storeList"
					grid="column-6"
					v-if="isStoreListReady">
				</store-grid>
			</div>

			<div class="content-blog-grid mt-3">
				<div class="content-bar mb-2">
					<div class="bar-title">โปรโมชั่นยางรถยนต์ ล่าสุด</div>
					<router-link :to="routerPath.PROMOTION" class="view-more" title="โปรโมชั่นยางรถยนต์ล่าสุด">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
				</div>
				<div class="loading" v-if="!isContentReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<blog-content
					:contents="generalContent.promotions"
					:landing="routerPath.PROMOTION"
					v-if="isContentReady">
				</blog-content>
			</div>

			<div class="content-blog-grid mt-2">
				<div class="content-bar mb-2">
					<div class="bar-title">รวมข่าวสารวงการรถยนต์และยานยนต์</div>
					<router-link :to="routerPath.ARTICLE" class="view-more" title="รวมข่าวสารวงการรถยนต์และยานยนต์">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
				</div>
				<div class="loading" v-if="!isContentReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<blog-content
					:contents="generalContent.articlesLatest"
					:landing="routerPath.ARTICLE"
					v-if="isContentReady">
				</blog-content>
			</div>

			<div class="content-blog-grid mt-2">
				<div class="content-bar mb-2">
					<div class="bar-title">
						<span @click="knowledgeCategory = 'all'">รู้เรื่องยางรถยนต์</span>
						<div class="desktop-knowledge-category d-none d-md-block" v-if="isContentReady">
							<button :class="{ 'active': knowledgeCategory === item.filter }" 
									@click="knowledgeCategory = item.filter"
									v-for="(item, index) in generalContent.articlesByCategory.filter(x => x.filter !== 'all')"
									:key="index">
								{{ item.title }}
							</button>
						</div>
					</div>
					<router-link :to="routerPath.ARTICLE" class="view-more" title="รู้เรื่องยางรถยนต์">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
				</div>
				<div class="loading" v-if="!isContentReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<template v-if="isContentReady">
					<div class="mobile-knowledge-category d-md-none" v-if="isContentReady">
						<dropdown
							v-model="knowledgeCategory"
							:options="getOptionArticleCategory()"
							:noTyping="true"
							class="input-option"
							:classes="{ input: ['square'] }">
						</dropdown>
					</div>
					<div v-for="(item, index) in generalContent.articlesByCategory"
						:key="index">
						<blog-content
							:contents="item.contents"
							:landing="routerPath.ARTICLE"
							v-if="knowledgeCategory === item.filter">
						</blog-content>
					</div>
				</template>
			</div>

			<div class="network mt-2 mb-2">
				<div class="content-bar mb-3">
					<div class="bar-title">NETWORK SHOP</div>
					<router-link :to="routerPath.NETWORK" class="view-more" title="ร้านที่มีเครือข่าย">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
				</div>
				<div class="loading" v-if="!isContentReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<home-network-shop :network="generalContent.network" v-if="isContentReady"></home-network-shop>
			</div>

			<gadget-splash-video :content="generalContent.splashVideo" v-if="isContentReady"></gadget-splash-video>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import HomeService from '@/services/homeService';
import ProductService from '@/services/productService';
import StoreService from '@/services/storeService';
import MasterService from '@/services/masterService';
import HomeTags from '@/components/home/Tags';
import HomeSliderBanner from '@/components/home/SliderBanner';
import HomeTireSearch from '@/components/home/TireSearch';
import HomeGadgetSearch from '@/components/home/GadgetSearch';
import HomeBrandsList from '@/components/home/BrandsList';
import HomeBrandsListMobile from '@/components/home/BrandsListMobile';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import GadgetSplashVideo from '@/components/gadget/SplashVideo';
import HomeNetworkShop from '@/components/home/NetworkShop';
import BlogContent from '@/components/blog/BlogContent';
import ProductGridHome from '@/components/product/ProductGridHome';
import StoreGrid from '@/components/store/StoreGridList';
import RouterPath from '@/router/path';

export default {
	components: {
		HomeTags,
		HomeSliderBanner,
		HomeTireSearch,
		HomeGadgetSearch,
		HomeBrandsList,
		HomeBrandsListMobile,
		GadgetAdsBanner,
		GadgetSplashVideo,
		HomeNetworkShop,
		BlogContent,
		ProductGridHome,
		StoreGrid
	},
	data() {
		return {
			isContentReady: false,
			isSliderReady: false,
			isBrandListReady: false,
			isPopularTireReady: false,
			isStoreListReady: false,
			generalContent: null,
			sliderContent: null,
			brandsContent: null,
			popularTire: null,
			storeList: [],
			routerPath: RouterPath,
			tireSizeOptionData: null,
			carBrandData: [],
			tireTypeData: [],
			knowledgeCategory: 'all'
		};
	},
	mounted() {
        this.initTireOption();
        this.initCarOption();
		this.initTireTypeOption();
        this.getBannerSlider();
		this.getBrands();
		this.getContent();
		this.getPopularTire();
		this.getStoreList();
    },
    methods: {
		async initTireOption() {
            const result = await MasterService.getAllTireSizeOption();

            this.tireSizeOptionData = result.data;
        },
        async initCarOption() {
            const result = await MasterService.getCarBrand();

            this.carBrandData = result.data;
        },
		async initTireTypeOption() {
            const result = await MasterService.getTireType();

            this.tireTypeData = result.data;
        },
		async getBannerSlider() {
            this.isSliderReady = false;

            const result = await HomeService.getBannerSlider();

			this.sliderContent = result.data;
            this.isSliderReady = true;
        },
		async getBrands() {
            this.isBrandListReady = false;

            const result = await HomeService.getBrands();

			this.brandsContent = result.data;
            this.isBrandListReady = true;
        },
        async getContent() {
            this.isContentReady = false;

            const result = await HomeService.getContent();

			this.generalContent = result.data;
            this.isContentReady = true;
        },
		async getPopularTire() {
            this.isPopularTireReady = false;

            const result = await ProductService.getPopularTire(12, true);

			this.popularTire = result.data;
            this.isPopularTireReady = true;
        },
		async getStoreList() {
			this.isStoreListReady = false;

			const result = await StoreService.getRandomStoreList();

			this.storeList = result.data;
            this.isStoreListReady = true;
		},
		getOptionArticleCategory() {
			const option = [];

			this.generalContent.articlesByCategory.forEach((item) => {
				option.push({
					id: item.filter,
					name: item.title
				});
			});

			return option;
		}
    },
	metaInfo() {
		const title = 'ราคายางรถยนต์วันนี้ เช็คราคายาง โปรโมชั่นยาง ร้านยาง | YELLOWTiRE';
		const desc = 'เช็คราคายางรถยนต์ทุกรุ่น ทุกขนาด ค้นหายางรถยนต์ทุกยี่ห้อ พร้อมข้อมูลยาง และโปรโมชั่น ราคายางอัพเดตล่าสุด รวมร้านยางรถยนต์ใกล้ฉันทั่วไทย | YELLOWTiRE.com ที่สุดเรื่องยาง เช็ค ชอป ชัวร์';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.home {
	padding: 0 0 15px;

	.top-section {
		padding: 15px 0 0;
		background: #fff;

		@media only screen and (max-width: $screenLarge) {
			width: 100%;
			height: auto;
			margin-left: 0;
		}
	}

	.title {
		margin: 20px 10px;
		text-align: center;

		h1 {
			font-family: 'Open Sans', 'Kanit';
			font-size: 18px;
			font-weight: bold;
			line-height: 20px;
			color: #555;
			margin: 0;

			@media only screen and (max-width: $screenExtraSmall) {
				font-size: 16px;
				line-height: 18px;
			}
		}

		p {
			font-size: 13px;
			line-height: 15px;
			color: #888;
			margin: 4px 0 0;

			@media only screen and (max-width: $screenExtraSmall) {
				font-size: 11px;
				line-height: 14px;
			}
		}
	}

	.tags {
		.loading {
			height: 70px;
		}
	}

	.main {
		display: flex;

		.banners {
			width: 900px;
			height: 320px;
			background: #eee;
			margin-left: 5px;

			@media only screen and (max-width: $screenLarge) {
				width: 100%;
				height: auto;
				margin-left: 0;
			}

			img {
				width: 100%;
			}
		}

		.search-tire {
			flex: 1;
			height: 100%;
		}
	}

	.search-store {
		height: 66px;
	}

	.mobile-search {
		.row {
			margin: 0 -5px;

			.col {
				padding: 0 5px;
			}
		}
	}

	.brands {
		.loading {
			.item {
				height: 100px;
			}
		}
	}

	.section-tire-search-desktop {
		display: flex;
		margin-top: 10px;

		.brands {
			width: 100%;
			order: 1;

			@media only screen and (max-width: $screenLarge) {
				width: 60%;
				order: 2;
				padding-left: 15px;
			}
		}

		.mobile-search {
			width: 40%;
			order: 1;

			@media only screen and (max-width: $screenSmall) {
				width: 100%;
				max-width: 400px;
				margin: auto;
			}
		}
	}

	.store-list {
		.loading {
			.item {
				height: 150px;
			}
		}
	}

	.network {
		.loading {
			.wrapper {
				padding: 0 10px 10px;
			}

			.item {
				height: 100px;

				@media only screen and (max-width: $screenLarge) {
					height: 75px;
				}
			}
		}
	}

	.product-recommend {
		.loading {
			.item {
				height: 250px;
			}
		}
	}
}
</style>