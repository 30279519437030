<template>
    <section class="home-tags">
        <div class="title d-none d-md-block">
            <div class="box">
                ค้นหา
                <br />
                ยอดนิยม
            </div>
        </div>
        <div class="tags" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
            <div class="group">
                <h2 v-for="(item, index) in tags.tireByWidth" :key="'w' + index">
                    <router-link :to="routerPath.TIRE + '?' + item.param" :title="item.text">
                        {{ item.text }}
                    </router-link>
                </h2>
                <h2 v-for="(item, index) in tags.tireByCarType" :key="'c' + index">
                    <router-link :to="routerPath.TIRE + '?' + item.param" :title="item.text">
                        {{ item.text }}
                    </router-link>
                </h2>
            </div>
        </div>
        <div class="tags" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
            <div class="group">
                <h2 v-for="(item, index) in tags.tireBySize" :key="'s' + index">
                    <router-link :to="routerPath.TIRE + '?' + item.param" :title="item.text">
                        {{ item.text }}
                    </router-link>
                </h2>
            </div>
        </div>
        <div class="tags" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
            <div class="group">
                <h2 v-for="(item, index) in tags.tireByType" :key="'s' + index">
                    <router-link :to="routerPath.TIRE + '?' + item.param" :title="item.text">
                        {{ item.text }}
                    </router-link>
                </h2>
            </div>
        </div>
    </section>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default {
    mixins: [ MixinDragScroll ],
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper
        };
    },
    props: {
        tags: Object
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.home-tags {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 90px;

    @media only screen and (max-width: $screenSmall) {
        padding-left: 0;
    }

    .title {
        position: absolute;
        top: 0;
        left: 0;
        
        .box {
            font-size: 12px;
            font-weight: bold;
            line-height: 12px;
            background: #666;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 70px;
            height: 66px;

            &:after {
                content: " ";
                position: absolute;
                right: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 33px 0 33px 10px;
                border-color: transparent transparent transparent #666;
            }

            @media only screen and (max-width: $screenSmall) {
                display: flex;
                width: 100px;
                height: 22px;
                font-weight: normal;

                &:after {
                    right: -7px;
                    border-width: 11px 0 11px 7px;
                }
            }
        }
    }

    .tags {
        overflow: hidden;

        .group {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    h2 {
        font-size: 12px;
        line-height: 12px;
        padding: 4px 8px;
        margin: 0 3px 3px 0;
        background: #e5e5e5;
    }

    a {
        white-space: nowrap;
        color: #000;
    }
}
</style>