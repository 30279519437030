<template>
    <section class="menu">
        <!-- <router-link :to="routerPath.TIRE_CALCULATOR" title="คำนวณขนาดยาง">
            <img src="@/assets/icon-calculator.png" title="คำนวณขนาดยาง" alt="คำนวณขนาดยาง" />
            <span>คำนวณขนาดยาง</span>
        </router-link> -->
        <a :href="helper.getFacebookLink()" target="_blank">
            <img src="@/assets/icon-technician.png" title="ให้ช่างแนะนำยาง" alt="ให้ช่างแนะนำยาง" />
            <span>ให้ช่างแนะนำยาง</span>
        </a>
        <router-link :to="routerPath.STORE" title="ค้นหาร้านยาง">
            <img src="@/assets/icon-pin.png" title="ค้นหาร้านยาง" alt="ค้นหาร้านยาง" />
            <span>ค้นหาร้านยาง</span>
        </router-link>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default {
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath
        };
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.menu {
    padding: 0 10px;

    a, button {
        display: block;
        width: 100%;
        height: 35px;
        margin-top: 5px;

        background: #ffc000;
        border: 2px solid #555;
        padding: 5px;

        position: relative;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #333;
        @include borderRadius(5px);

        img {
            position: absolute;
            z-index: 1;
            left: 15px;
            height: 20px;
        }
    }
}

// .menu {
//     display: flex;
//     justify-content: space-between;

//     a, button {
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         width: 32%;
//         height: 76px;
//         background: #ffc000;
//         border: 2px solid #555;
//         margin: 5px 0 0;
//         padding: 7px 5px 5px;
//         position: relative;
//         @include borderRadius(5px);

//         img {
//             display: block;
//             margin: 0 auto 5px;
//             height: 20px;
//         }

//         span {
//             display: block;
//             width: 100%;
//             font-size: 13px;
//             line-height: 15px;
//             font-weight: bold;
//             text-align: center;
//             color: #333;
//         }
//     }
// }
</style>