import Helper from '@/services/helper';
import MasterService from '@/services/masterService';
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            option: {
                width: [],
                ratio: [],
                diameter: [],
                brand: [],
                model: [],
                usage: []
            },
            searchMode: 1,
            formSearchTire: this.initFormSearchTire(),
            formSearchCar: this.initFormSearchCar(),
            formSearchType: this.initFormSearchType()
        };
    },
    props: {
        tireSizeOption: Object,
        carBrandData: Array,
        tireTypeData: Array
    },
    mounted() {
        this.initTireOption();
        this.initCarBrandOption();
        this.initTypeOption();
        this.initializeSelectedOption();
    },
    methods: {
        initFormSearchTire() {
            return {
                width: null,
                ratio: null,
                diameter: null
            }
        },
        initFormSearchCar() {
            return {
                brand: null,
                model: null
            }
        },
        initFormSearchType() {
            return {
                usage: null
            }
        },
        initTireOption() {
            this.option.width = Helper.setupOption(this.tireSizeOption.width);
            this.option.ratio = Helper.setupOption(this.tireSizeOption.ratio);
            this.option.diameter = Helper.setupOption(this.tireSizeOption.diameter);
        },
        initCarBrandOption() {
            this.option.brand = Helper.setupOption(this.carBrandData);
        },
        initTypeOption() {
            this.tireTypeData.forEach((item) => {
                this.option.usage.push({
                    id: item.param + '|' + item.value,
                    name: item.text
                });
            });
        },
        initializeSelectedOption() {
            const carBrand = Helper.getQueryString('cb');
            const carModel = Helper.getQueryString('cm');
            const tireWidth = Helper.getQueryString('tw');
            const tireRatio = Helper.getQueryString('tr');
            const tireDiameter = Helper.getQueryString('td');
            const feature = Helper.getQueryString('ft');
            const special = Helper.getQueryString('sp');

            if (carBrand
                || carModel) {
                this.searchMode = 2;

                this.formSearchCar.brand = carBrand ? carBrand : '';
                this.formSearchCar.model = carModel ? carModel : '';
            } else if (tireWidth
                || tireRatio
                || tireDiameter) {
                this.searchMode = 1;

                this.formSearchTire.width = tireWidth ? tireWidth : '';
                this.formSearchTire.ratio = tireRatio ? tireRatio : '';
                this.formSearchTire.diameter = tireDiameter ? tireDiameter : '';
            } else if (feature
                || special) {
                this.searchMode = 3;

                this.usage

                if (feature) {
                    this.formSearchType.usage = feature ? 'ft' + '|' + feature : '';
                } else if (special) {
                    this.formSearchType.usage = special ? 'sp' + '|' + special : '';
                }
            }
        },
        async changeTireSizeOption() {
            setTimeout(async () => {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getTireSizeByCondition(this.formSearchTire);
                loader.hide();

                this.option.width = Helper.setupOption(result.data.width);
                this.option.ratio = Helper.setupOption(result.data.ratio);
                this.option.diameter = Helper.setupOption(result.data.diameter);
            });
        },
        async changeBrand(brand) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getCarModel(brand);
                loader.hide();

                this.option.model = Helper.setupOption(result.data);
            } else {
                this.option.model = [];
            }

            // Handle related option
            this.formSearchCar.model = this.option.model.find(x => x.id === this.formSearchCar.model) ? this.formSearchCar.model : '';
        },
        searchBySize() {
            const param = [
                'tw=' + (this.formSearchTire.width !== null ? this.formSearchTire.width : ''),
                'tr=' + (this.formSearchTire.ratio !== null ? this.formSearchTire.ratio : ''),
                'td=' + (this.formSearchTire.diameter !== null ? this.formSearchTire.diameter : '')
              ].join('&');
            const targetRoute = RouterPath.TIRE + '?' + param;
          
            // Check if the target route is the same as the current route
            if (this.$route.fullPath === targetRoute) {
              return;
            }
          
            this.$router.push(targetRoute);
        },
        searchByCar() {
            if (this.validateCar()) {
                const param = [
                    'cb=' + (this.formSearchCar.brand !== null ? this.formSearchCar.brand : ''),
                    'cm=' + (this.formSearchCar.model !== null ? this.formSearchCar.model : '')
                ].join('&');
                const targetRoute = RouterPath.TIRE_BY_CAR + '?' + param;
              
                // Check if the target route is the same as the current route
                if (this.$route.fullPath === targetRoute) {
                  return;
                }
              
                this.$router.push(targetRoute);
            }
        },
        searchByType() {
            if (this.formSearchType.usage) {
                const parts = this.formSearchType.usage.split('|');
                const param = parts[0] + '=' + parts[1];
                const targetRoute = RouterPath.TIRE_BY_TYPE + '?' + param;
              
                // Check if the target route is the same as the current route
                if (this.$route.fullPath === targetRoute) {
                  return;
                }
              
                this.$router.push(targetRoute);   
            }
        },
        validateCar() {
            if (this.formSearchCar.brand && this.formSearchCar.model) {
                return true;
            } else {
                this.$swal(Helper.warningAlert('ข้อมูลยังไม่ครบถ้วน', 'โปรดเลือกข้อมูลรถให้ครบถ้วน'));
                return false;
            }
        }
    }
}