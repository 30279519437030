<template>
    <section class="home-brands" v-if="brandSet.length > 0">
        <swiper :options="swiperOptions" class="sliders">
            <swiper-slide v-for="(itemSet, indexSet) in brandSet" :key="indexSet">
                <div class="brand-set">
                    <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, itemBrand.param)" :title="'ยางรถยนต์ ' + itemBrand.title"  v-for="(itemBrand, indexBrand) in itemSet" :key="indexBrand">
                        <img :src="itemBrand.img" :alt="'ยางรถยนต์ ' + itemBrand.title" :title="'ยางรถยนต์ ' + itemBrand.title" @error="helper.setNoImg($event, 'brand')" />
                        <h2>
                            ราคายางรถยนต์
                            <br />
                            {{ itemBrand.title }}
                        </h2>
                    </router-link>
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default {
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper,
            swiperOptions: {
                loop: true,
                autoplay: {
                    delay: 3000
                }
            },
            brandSet: []
        };
    },
    props: {
        brands: Array
    },
    mounted() {
        this.setupBrand();
    },
    methods: {
        setupBrand() {
            const size = 8;
            const brands = this.brands;

            for (let index = 0; index < brands.length; index += size) {
                this.brandSet.push(brands.slice(index, index + size));
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.home-brands {
    @include gridContentWrapper(0 -3px);

    .sliders {
        overflow: hidden;

        .brand-set {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            a {
                width: 25%;
                padding: 3px;

                img {
                    display: block;
                    width: 100%;
                    border: 1px solid #ccc;
                }

                h2 {
                    margin: 4px 0 0;
                    font-size: 12px;
                    line-height: 12px;
                    color: #000;
                    text-align: center;
                }
            }
        }
    }
}
</style>