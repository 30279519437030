<template>
    <section class="home-brands">
        <router-link :to="helper.replaceProductParam(routerPath.TIRE_BRAND, item.param)" v-for="(item, index) in brands" :key="index" :title="'ยางรถยนต์ ' + item.title">
            <img :src="item.img" :alt="'ยางรถยนต์ ' + item.title" :title="'ยางรถยนต์ ' + item.title" @error="helper.setNoImg($event, 'brand')" />
            <h2>
                ราคายางรถยนต์
                <br />
                {{ item.title }}
            </h2>
        </router-link>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default {
    data() {
        return {
            routerPath: RouterPath,
            helper: Helper
        };
    },
    props: {
        brands: Array
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.home-brands {
    justify-content: center;
    @include gridContentWrapper(0 -10px);

    @media only screen and (max-width: $screenLarge) {
        @include gridContentWrapper(0 -3px);
    }

    a {
        position: relative;
        display: block;
        margin: 0 10px 10px;

        img {
            display: block;
            width: 100px;
            border: 1px solid #ccc;

            @media only screen and (max-width: $screenMedium) {
                width: 90px;
            }
        }

        h2 {
            margin: 4px 0 0;
            font-size: 12px;
            line-height: 12px;
            color: #000;
            text-align: center;
        }
    }
}
</style>