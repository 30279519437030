<template>
    <section class="tire-search">
        <div class="title">ค้นหายางของคุณ</div>

        <div class="search-option mb-2">
            <button class="btn-option" :class="{ 'active': searchMode === 1 }" @click="searchMode = 1">
                ขนาดยาง
            </button>
            <button class="btn-option" :class="{ 'active': searchMode === 2 }" @click="searchMode = 2">
                จากรุ่นรถ
            </button>
             <button class="btn-option" :class="{ 'active': searchMode === 3 }" @click="searchMode = 3">
                การใช้งาน
            </button>
        </div>

        <div class="search-mode" v-show="searchMode === 1">
            <div class="field-option">
                <dropdown
                    v-model="formSearchTire.width"
                    placeholder="ความกว้าง"
                    :classes="{ input: [ 'text-center' , 'input-option' ] }"
                    :options="option.width"
                    @change="changeTireSizeOption()">
                </dropdown>
            </div>
            <div class="field-option">
                <dropdown
                    v-model="formSearchTire.ratio"
                    placeholder="ซีรีย์"
                    :classes="{ input: [ 'text-center' , 'input-option' ] }"
                    :options="option.ratio"
                    @change="changeTireSizeOption()">
                </dropdown>
            </div>
            <div class="field-option">
                <dropdown
                    v-model="formSearchTire.diameter"
                    placeholder="ขอบล้อ"
                    :classes="{ input: [ 'text-center' , 'input-option' ] }"
                    :options="option.diameter"
                    @change="changeTireSizeOption()">
                </dropdown>
            </div>
            <div class="field-option">
                <button class="btn btn-black btn-search" @click="searchBySize()">
                    <img src="@/assets/icon-tire.png" title="ค้นหายาง" alt="ค้นหายาง" />
                    ค้นหายาง
                </button>
            </div>
        </div>
        <div class="search-mode" v-show="searchMode === 2">
            <div class="field-option">
                <dropdown
                    v-model="formSearchCar.brand"
                    placeholder="ยี่ห้อรถ"
                    :classes="{ input: [ 'text-center', 'input-option' ] }"
                    :options="option.brand"
                    @change="changeBrand($event)">
                </dropdown>
            </div>
            <div class="field-option">
                <dropdown
                    v-model="formSearchCar.model"
                    placeholder="รุ่นรถ"
                    :classes="{ input: [ 'text-center', 'input-option' ] }"
                    :options="option.model">
                </dropdown>
            </div>
            <div class="field-option">
                <button class="btn btn-black btn-search" @click="searchByCar()">
                    <img src="@/assets/icon-tire.png" title="ค้นหายาง" alt="ค้นหายาง" />
                    ค้นหายาง
                </button>
            </div>
        </div>
        <div class="search-mode" v-show="searchMode === 3">
            <div class="field-option">
                <dropdown
                    v-model="formSearchType.usage"
                    placeholder="การใช้งาน"
                    :classes="{ input: [ 'text-center', 'input-option' ] }"
                    :options="option.usage">
                </dropdown>
            </div>
            <div class="field-option">
                <button class="btn btn-black btn-search" @click="searchByType()">
                    <img src="@/assets/icon-tire.png" title="ค้นหายาง" alt="ค้นหายาง" />
                    ค้นหายาง
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import MixinTireSearch from '@/mixins/tireSearch';

export default {
    mixins: [ MixinTireSearch ],
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.tire-search {
    padding: 10px;
    background: $mainColor;
    height: 240px;

    @media only screen and (max-width: $screenSmall) {
        height: auto;
    }

    .title {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 7px;
    }

    .search-option {
        display: flex;
        justify-content: space-between;
    }

    .btn-option {
        font-size: 12px;
        margin: 0 2px 5px;
        padding: 4px 0;
        text-align: center;
        border: 2px solid #666;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        opacity: 0.5;
        @include borderRadius(5px);

        &.active {
            border: 2px solid #555;
            background: #e6d509;
            font-weight: bold;
            opacity: 1;
        }
    }

    .search-mode {
        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }

        .field-option {
            padding-bottom: 5px;

            @media only screen and (max-width: $screenExtraSmall) {
                width: 100%;
                padding: 4px 0;
            }
        }
        
        .btn-search {
            position: relative;
            width: 100%;
            height: 35px;
            display: block;
            margin-top: 2px;
            font-size: 14px;
            font-weight: bold;

            @media only screen and (max-width: $screenLarge) {
                margin: 0;
                font-size: 13px;
            }

            img {
                position: absolute;
                z-index: 1;
                left: 15px;
                width: 20px;
                height: 20px;
            }
        }
    }
}

::v-deep .form-option {
    border: none;

    .input-option {
        font-size: 14px;
        height: 32px;
        border: none;

        @media only screen and (max-width: $screenLarge) {
            font-size: 13px;
        }
    }
}
</style>