<template>
    <div class="modal-frame" v-if="content.isEnable && isDisplay" @click="hideSplashVideo()">
        <div class="video">
            <button class="btn-hide" @click="hideSplashVideo()"><b-icon icon="x"></b-icon></button>
            <div class="wrapper">
                <div class="frame">
                    <iframe :src="content.youtube" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDisplay: false
        }
    },
    props: {
        content: Object
    },
    mounted() {
        this.isDisplay = this.checkDisplayCondition();
    },
    methods: {
        checkDisplayCondition() {
            if (this.content.isEnable) {
                if (!this.content.isPermanent) {
                    const storageSplash = localStorage.getItem(this.content.aliasName);

                    if (storageSplash) {
                        const splashVideo = JSON.parse(window.atob(storageSplash));

                        if (splashVideo.expiration && new Date().getTime() > splashVideo.expiration) {
                            localStorage.removeItem(this.content.aliasName);
                        } else {
                            return splashVideo.isDisplay;
                        }
                    }
                }

                return true;
            }

            return false;
        },
        hideSplashVideo() {
            this.isDisplay = false;

            if (!this.content.isPermanent) {
                const param = {
                    isDisplay: false,
                    expiration: new Date().getTime() + (this.content.hidePeriod  * 60 * 60 * 1000),
                };

                localStorage.setItem(this.content.aliasName, window.btoa(JSON.stringify(param)));
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-frame {
    position: fixed;
    padding: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);

    .video {
        position: relative;
        width: 100%;
        padding: 50px;
        max-width: 700px;
        background: #333;
        @include boxShadow(0 0 15px rgba(0, 0, 0, 0.7));

        .btn-hide {
            position: absolute;
            top: -35px;
            right: 0;
            font-size: 21px;
            width: 30px;
            height: 30px;
            padding: 0;
            border: none;
            background: #fff;
            @include borderRadius(50%);

            @media only screen and (max-width: $screenSmall) {
                top: -27px;
                font-size: 18px;
                width: 24px;
                height: 24px;
            }
        }

        .wrapper {
            padding-top: 56.25%;

            .frame {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                iframe {
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }
}
</style>