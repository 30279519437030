<template>
    <section class="product-grid">
        <div class="product"
            v-for="(item, index) in productsWithAds"
            :key="'product' + index">
            <product-box
                v-if="!item.isAds"
                :useH2="false"
                :product="item.data"
                trackingReferrer="home">
            </product-box>

            <div class="banner" v-if="item.isAds">
                <a :href="item.data.link"
                    class="wrapper" target="_blank"
                    :name="'bannerStoreAds' + item.data.name">
                    <img :src="getAdsDisplay(item.data.pictures)"
                        :alt="item.data.name"
                        :title="item.data.name" />
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import Helper from '@/services/helper';
import MixinScreenSize from '@/mixins/screenSize';
import Constants from '@/variables/constants';
import RouterPath from '@/router/path';
import ProductBox from '@/components/product/ProductBox';

export default {
    mixins: [ MixinScreenSize ],
    components: {
        ProductBox
    },
    data() {
        return {
            helper: Helper,
            constants: Constants,
            routerPath: RouterPath,
            productsWithAds: []
        };
    },
    props: {
        products: {
            type: [ Array, Object ],
            required: true
        },
        promoteData: {
            type: Array,
            required: false,
            default: () => []
        },
        adsPosition: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    mounted() {
        this.getProductWithAds();
    },
    methods: {
        getProductWithAds() {
            this.productsWithAds = [];
            let promoteIndex = 0;

            this.products.forEach((item) => {
                this.productsWithAds.push({
                    isAds: false,
                    data: item
                });
            });

            if (this.products.length > 0 && this.adsPosition.length > 0) {
                this.adsPosition.forEach((position) => {
                    if (this.promoteData.length > 0) {
                        this.productsWithAds.splice(position, 0, {
                            isAds: true,
                            data: this.promoteData[promoteIndex]
                        });

                        promoteIndex++;
                    }
                });
            }

            // Cut data display
            this.productsWithAds = this.productsWithAds.slice(0, 12);
        },
        getAdsDisplay(pictures) {
            if (this.windowWidth > this.screenLarge) {
                return pictures.find(x => x.size === 'xl').img;
            } else if (this.windowWidth > this.screenMedium) {
                return pictures.find(x => x.size === 'l').img;
            } else if (this.windowWidth > this.screenSmall) {
                return pictures.find(x => x.size === 'm').img;
            }

            return pictures.find(x => x.size === 's').img;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-grid {
    display: flex;
    flex-wrap: wrap;

    .product {
        display: block;
        padding: 0 2px 4px;
        width: 16.6666%;

        @media only screen and (max-width: $screenMedium) {
            width: 33.33333%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 50%;
            padding: 0 2px 2px;
        }
    }

    .banner {
        .wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            background: #333;
            overflow: hidden;
        }

        img {
            display: block;
            width: 100%;
        }

        .size-xl {
            @media only screen and (max-width: $screenLarge) {
                display: none;
            }
        }

        .size-l {
            display: none;

            @media only screen and (max-width: $screenLarge) {
                display: block;
            }

            @media only screen and (max-width: $screenMedium) {
                display: none;
            }
        }

        .size-m {
            display: none;

            @media only screen and (max-width: $screenMedium) {
                display: block;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                display: none;
            }
        }

        .size-s {
            display: none;

            @media only screen and (max-width: $screenExtraSmall) {
                display: block;
            }
        }
    }
}

.no-data {
    margin: auto;
}
</style>