export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            screenExtraLarge: 1400,
            screenLarge: 1200,
            screenMedium: 992,
            screenSmall: 768,
            screenExtraSmall: 576
        };
    },
    mounted() {
        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods: {  
        onResize() {
          this.windowWidth = window.innerWidth;
        }
    }
}